@font-face {
    font-family: 'GenshinImpact';
    src: url(./assets/fonts/ja-jp.woff2) format('woff2'),
		 url(./assets/fonts/ja-jp.ttf) format('truetype');
}

html, body {
	height: 100%;
	width: 100%;
	padding: 0;
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

* {
	box-sizing: border-box;
}
